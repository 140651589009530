
import { Component, Vue } from "vue-property-decorator";
import { _appkeyList } from "@/utils/pageConfig/merchant/_appkeyList";
import { Foundation } from "@/utils/Foundation";
import editSecret from "./components/editSecret.vue";
import API_MerchantList from '@/utils/API/merchant/MerchantList';

@Component({
  components: {
    editSecret,
  },
})
export default class MerchantAppkey extends Vue {
  /*表格相关*/
  pageConfig: any = _appkeyList;
  tableData: any = [];
  total: number = 0;
  params: any = {
    current: 1,
    size: 20,
  };

  mounted() {
    this.getTableData();
  }

  /*获取表格数据*/
  async getTableData() {
    let res: any = await API_MerchantList.getMerchantAppkeyList(this.params);
    this.tableData =
      (res.records &&
        res.records.length > 0 &&
        res.records.map((e: any) => {
          e._createTime = Foundation.dateFormat(e.createTime);
          return e;
        })) ||
      [];
    this.total = res.total;
  }

  /*底部分页切换*/
  currentChange(pageNumber: number) {
    this.params.current = pageNumber;
    this.getTableData();
  }

  /*底部分页条数切换*/
  sizeChange(sizeNumber: number) {
    this.params.size = sizeNumber;
    this.getTableData();
  }

  /*查询*/
  handleSearch() {
    let query: any = Foundation.resetParams((this.$refs.searchForm as any).form, true);
    this.params = {
      current: 1,
      size: 20,
      ...query,
    };
    this.getTableData();
  }

  /*修改*/
  async handleEdit(data: any) {
    this.$confirm("是否重置当前secret?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        let params = {
          merchantAppkeyId: data.id,
          secret: "", //预留字段，暂时传空值
        };
        API_MerchantList.updateSecret(params)
          .then((res: any) => {
            this.$message({
              type: "success",
              message: "重置成功!",
            });
          })
          .catch(() => {
            this.$message({
              type: "warning",
              message: "重置失败，请稍后再试!",
            });
          });
      })
      .catch(() => {});
  }
}
