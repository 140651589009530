
import { Component, Vue } from "vue-property-decorator";
import { _merchantList } from "@/utils/pageConfig/merchant/_merchantList";
import { Foundation } from "@/utils/Foundation";
import API_MerchantList from '@/utils/API/merchant/MerchantList';

@Component({
  name: "editSecret",
})
export default class extends Vue {
  pageConfig: any = _merchantList;
  form: any = {};
  isLoading: boolean = false;

  show(data: any = {}) {
    (this.$refs.editsecret as any).show();
    this.$nextTick(() => {
      this.form = Foundation.deepClone(data);
      (this.$refs.addForm as any).clearValidate();
    });
  }

  hide() {
    (this.$refs.editsecret as any).hide();
  }

  /*表单提交*/
  async submitForm() {
    let form = Foundation.resetParams(this.form);
    let data: object = {
      merchantAppkeyId: this.form.id,
      secret:this.form.secret
    };
    (this.$refs.addForm as any).validate(async (valid: any) => {
      if (valid) {
        if (this.isLoading) return;
        this.isLoading = true;

        try {
          await API_MerchantList.updateSecret(data);
          this.isLoading = false;
          this.$message.success("操作成功");
          this.hide();
          this.$emit("submitForm", form);
        } catch (e) {
          this.isLoading = false;
        }
      } else {
        return false;
      }
    });
  }
}
