import { render, staticRenderFns } from "./merchantAppkey.vue?vue&type=template&id=d33a97a4&scoped=true"
import script from "./merchantAppkey.vue?vue&type=script&lang=ts"
export * from "./merchantAppkey.vue?vue&type=script&lang=ts"
import style0 from "./merchantAppkey.vue?vue&type=style&index=0&id=d33a97a4&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d33a97a4",
  null
  
)

export default component.exports