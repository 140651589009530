export const _appkeyList = {
    //筛选
    search: [
        {
            type: 'el-input',
            label: '商户code：',
            value: 'merchantCode',
            props: {
                placeholder: '请输入',
                clearable: true
            },
        },
        {
            type: 'el-input',
            label: 'appkey',
            value: 'appkey',
            props: {
                placeholder: '请输入',
                clearable: true
            },
        },
        {
            type: 'el-input',
            label: '商户名称',
            value: 'merchantName',
            props: {
                placeholder: '请输入',
                clearable: true
            },
        }
    ],
    //列表
    table: [
        {
            type: 'index',
            label: '序号',
            width: '50'
        },
        {
            prop: 'merchantCode',
            label: '商户code',
            width: '100'
        },
        {
            prop: 'merchantName',
            label: '商户名称'
        },

        {
            prop: 'appkey',
            label: 'appkey',
            width: '300'
        },
        {
            prop: 'secret',
            label: 'secret',
            width: '300'
        },
        {
            prop: '_createTime',
            label: '时间'
        },
        {
            label: '操作',
            self: {
                body: 'operation'
            }
        }
    ],
    //列表
    info: [
        {
            prop: 'coName',
            label: '商户名称'
        },
        {
            prop: 'coCode',
            label: '商户标识'
        },
        {
            prop: 'coTag',
            label: '商户tag'
        },
        {
            prop: '_addr',
            label: '所在地区'
        },
        {
            prop: '_status',
            label: '状态'
        },
        {
            prop: 'remark',
            label: '备注'
        },
        {
            prop: '_login',
            label: '登录地址'
        },
        {
            prop: '_createTime',
            label: '录入时间'
        }
    ],
    //规则
    rules: {
        coName: [{ required: true, message: '请填写商户名称', trigger: 'blur' }]
    },
    //状态
    status: [
        {
            label: '启用',
            value: 0,
        },
        {
            label: '维护中',
            value: 1,
        },
        {
            label: '已结束',
            value: 2,
        }
    ]
};
